
.course-ware-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;

    .resource-classify {
        display: flex;
        align-items: center;
        padding: 26px 30px;
        position: relative;

        .back-btn {
            position: absolute;
            right: 30px;
        }

        span {
            display: inline-block;
            line-height: 1;
            font-size: 14px;
            padding: 8px 12px;
            border-radius: 4px;
            font-weight: 400;
            cursor: pointer;
        }

        .class-item {
            background: #ECF5FF;
            border: 1px solid #DCECFE;
            color: #409EFF;
        }
    }

    .administration {
        /*display: flex;*/
        .administration-box {
            padding: 0 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .admin-btn {
                padding: 8px 28px;
            }
        }
    }

    .course-ware-list {
        flex: 1;
        width: 100%;
        height: 1%;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .course-ware {
            padding: 0px 30px;
            display: flex;
            flex-wrap: wrap;

            .dataTabble {
                width: 100%;
                margin-top: 18px;
                border-bottom: 1px solid #DDDDDD;

                .table-head {
                    width: 100%;
                    height: 50px;
                    background: #E6F0FE;;

                    .table-head-title {
                        height: 50px;
                        font-size: 16px;

                        th {
                            color: #333333;
                            font-weight: 400;
                            width: 25%;
                        }

                        .th-title1 {
                            text-align: left;
                            padding-left: 40px;
                        }
                    }
                }

                .table-body {
                    height: 100%;
                    border-bottom: 1px solid #DDDDDD;

                    .table-trBox {
                        height: 100px;
                        position: relative;

                        td {
                            text-align: center;
                        }

                        .td-title {
                            text-align: left;
                            padding-left: 40px;
                        }

                        .opt-box {
                            height: 100px;

                            a {
                                display: flex;
                                align-items: center;
                                width: 33%;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-box {
        text-align: center;
        margin-bottom: 60px;
    }

    .module-content {
        display: flex;
        justify-content: space-between;
        padding: 19px 20px;
        /*position: absolute;*/
        /*bottom: 20px;*/
        /*left: 0;*/
        /*right: 0;*/
        align-items: center;
        box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);

        .module {
            flex: 1;
            width: 1%;

            ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                white-space: nowrap;
            }
        }

        .module-list {
            display: flex;
            position: relative;
            padding: 6px;

            .new-module-item {
                position: relative;
                width: 60px;
                height: 60px;
                border-radius: 10px;
                margin-right: 15px;
            }

            .module-item {
                position: relative;
                width: 60px;
                height: 60px;
                border: 1px dashed #CCCCCC;
                border-radius: 10px;
                margin-right: 13px;
            }

            .item-cover {
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 50%;

                i {
                    font-size: 20px;
                    color: #fff;
                }
            }

            .item-cover1 {
                width: 60px;
                height: 60px;
                line-height: 60px;
                text-align: center;
                border-radius: 50%;
                position: relative;

                i {
                    font-size: 20px;
                    color: #fff;
                }

                .show-img {
                    width: 100%;
                    height: 100%;

                    .img-box {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 50%
                    }
                }
            }

            .show1 {
                background: #61BCEB;
            }

            .show2 {
                background: #3DCAC8;
            }

            .show3 {
                background: #F0BC49;
            }

            .show4 {
                background: #EC75A4;
            }

            .show5 {
                background: #79ACDC;
            }

            .show6 {
                background: #61BCEB;
            }

            .show7 {
                background: #5AB9E9;
            }

            .courseware {
                background: linear-gradient(0deg, #F5D971, #EDA92E);
            }

            .textbook {
                background: linear-gradient(0deg, #7BCBF2, #44ACE3);
            }

            .lesson-plan {
                background: linear-gradient(0deg, #53DED1, #2BB9BF);
            }

            .micro-lesson {
                background: linear-gradient(0deg, #EE89B5, #EA5D90);
            }

            .animation {
                background: linear-gradient(0deg, #F39E72, #EC6C6C);
            }

            .test-center {
                background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
            }

            .training-center {
                background: linear-gradient(0deg, #ED83AA, #EC6B6F);
            }

            .knowledge-points {
                background: linear-gradient(0deg, #77C9F1, #47AEE4);
            }

            .material-library {
                background: linear-gradient(0deg, #51DCD0, #2CBAC0);
            }

            .case-library {
                background: linear-gradient(0deg, #F5D971, #EDA92E);
            }

            .related-copywriting {
                background: linear-gradient(0deg, #8BC3EB, #628EC9);
            }

            .personal-info {
                background: linear-gradient(0deg, #51DCD0, #2FBDC1);
            }

            .del-btn {
                width: 18px;
                height: 18px;
                background: #FF0000;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                position: absolute;
                right: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 1;
            }

            .show-title {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 1;
            }

            .new-module-item:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                opacity: 0;
            }


            .new-module-item:hover:before {
                width: 100%;
                left: 0;
                opacity: 0.5;
            }

            .new-module-item:hover .del-btn {
                opacity: 1;
            }

        }

        .module-save-btn {
            width: 60px;
            height: 60px;
            background: #ECF5FF;
            border: 1px solid #DCECFE;
            border-radius: 4px;
            color: #409EFF;
            font-size: 14px;
            line-height: 60px;
            text-align: center;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}
